<template>
<b-modal
title="Pedidos eliminados"
size="lg"
hide-footer
id="deleted-orders">
	<table-component
	v-if="deleted_orders.length"
	:properties="modelPropertiesFromName('order')"
	:models="deleted_orders"
	model_name="order">
	</table-component>
	<p 
	v-else
	class="text-with-icon">
		<i class="icon-eye-slash"></i>
		No hay pedidos eliminados
	</p>
</b-modal>
</template>
<script>
export default {
	components: {
		TableComponent: () => import('@/common-vue/components/display/TableComponent')
	},
	computed: {
		deleted_orders() {
			return this.$store.state.order.deleted_models
		}
	}
}
</script>